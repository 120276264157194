<template>
  <v-sheet dark v-if="event && generalAssembly">
    <v-container
      class="general-assembly-container px-0 py-0"
      fluid
      :style="{ 'background-image': `url(${generalAssembly.background})` }"
    >
      <v-row class="general-assembly" no-gutters>
        <v-col cols="12" lg="2">
          <logo class="hidden-md-and-down" type="round" :logo="event.logoWeb" />

          <logo
            class="hidden-md-and-down"
            v-if="hasMoreThanOneImage"
            type="round"
            :logo="generalAssembly.coverImages[1]"
          />
        </v-col>

        <v-col class="content" cols="12" lg="8">
          <div class="room">
            <video-switcher :user="loggedInUser" :video="generalAssembly" />
          </div>

          <div class="question__wrapper" v-if="question">
            <div class="question__text">{{ question.question }}</div>

            <div class="question__times">
              <div v-if="!questionHasCloseDate" class="question__started">
                {{
                  $t('general-assembly.ongoing', { date: $d(Date.parse(question.created), 'time') })
                }}
              </div>

              <div v-else>
                <div class="times__started">
                  {{
                    $t('general-assembly.created', {
                      date: $d(Date.parse(question.created), 'time'),
                    })
                  }}
                </div>

                <div class="times__closed">
                  {{
                    $t('general-assembly.closed', {
                      date: $d(Date.parse(question.closedAt), 'time'),
                    })
                  }}
                </div>
              </div>
            </div>

            <div v-if="!questionHasCloseDate" class="question__controls">
              <v-btn
                class="control"
                color="primary"
                x-large
                @click="approve"
                :disabled="approveButtonIsDisabled"
                data-test-id="approve-btn"
              >
                {{ $t('general-assembly.buttons.approve') }}
              </v-btn>

              <v-btn
                class="control"
                color="error"
                x-large
                @click="reject"
                :disabled="rejectButtonIsDisabled"
                data-test-id="reject-btn"
              >
                {{ $t('general-assembly.buttons.reject') }}
              </v-btn>

              <v-btn
                class="control"
                color="gray"
                x-large
                @click="refrain"
                :disabled="refrainButtonIsDisabled"
                data-test-id="refrain-btn"
              >
                {{ $t('general-assembly.buttons.refrain') }}
              </v-btn>
            </div>

            <div v-if="questionHasCloseDate && answers" class="question__controls">
              <v-btn class="control control__disabled" color="primary" x-large outlined>
                {{ $t('general-assembly.buttons.approve-count', { count: approveCount() }) }}
              </v-btn>

              <v-btn class="control control__disabled" color="error" x-large outlined>
                {{ $t('general-assembly.buttons.reject-count', { count: rejectCount() }) }}
              </v-btn>

              <v-btn class="control control__disabled" color="gray" x-large outlined>
                {{ $t('general-assembly.buttons.refrain-count', { count: refrainCount() }) }}
              </v-btn>
            </div>
          </div>
        </v-col>

        <v-col class="general-assembly__chat" cols="12" lg="2">
          <v-sheet dark class="general-assembly__chat">
            <agenda class="agenda" :description="generalAssembly.agenda" />

            <chat
              v-if="generalAssembly.chatId"
              class="chat"
              :messages="moderatedMessages"
              :user="loggedInUser"
              @load-more="loadMessages"
              @send-message="sendMessage"
              @go-to-profile="goToProfile"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { AnswerType } from '@/models';

import Agenda from '@/components/agenda/Agenda.vue';
import Chat from '@/components/chat/Chat.vue';
import Logo from '@/components/logo/Logo.vue';
import VideoSwitcher from '@/components/video-switcher/VideoSwitcher.vue';

import {
  APP_ASSEMBLY_MODULE,
  ASSEMBLY_CHAT_LOAD_MESSAGES,
  ASSEMBLY_SEND_CHATS_MESSAGE,
  CONNECT_TO_ASSEMBLY_SOCKET,
  DISCONNECT_FROM_ASSEMBLY_SOCKET,
  GET_QUESTIONS,
  QUESTION_ANSWER,
} from '@/stores/umanize-app/actions/general-assembly/app-general-assembly.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { GET_ASSEMBLY_BY_ID } from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';

export default {
  name: 'GeneralAssembly',
  components: {
    Agenda,
    Logo,
    Chat,
    VideoSwitcher,
  },
  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APP_ASSEMBLY_MODULE, [
      'generalAssembly',
      'question',
      'moderatedMessages',
      'assemblyMessagesAreLoading',
    ]),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    hasAnswered() {
      return this.question.userAnswer;
    },
    hasMoreThanOneImage() {
      return this.generalAssembly?.coverImages?.length > 1;
    },
    questionHasCloseDate() {
      return !!this.question?.closedAt;
    },
    userCanVote() {
      return this.generalAssembly?.userAccess?.canVote;
    },
    approveButtonIsDisabled() {
      return (this.hasAnswered && !this.answered('approve')) || !this.userCanVote;
    },
    refrainButtonIsDisabled() {
      return (this.hasAnswered && !this.answered('refrain')) || !this.userCanVote;
    },
    rejectButtonIsDisabled() {
      return (this.hasAnswered && !this.answered('reject')) || !this.userCanVote;
    },
    answers() {
      return this.question.results;
    },
  },
  methods: {
    ...mapActions(APP_ASSEMBLY_MODULE, [
      GET_ASSEMBLY_BY_ID,
      GET_QUESTIONS,
      QUESTION_ANSWER,
      CONNECT_TO_ASSEMBLY_SOCKET,
      DISCONNECT_FROM_ASSEMBLY_SOCKET,
      ASSEMBLY_CHAT_LOAD_MESSAGES,
      ASSEMBLY_SEND_CHATS_MESSAGE,
    ]),
    sendMessage(message) {
      this[ASSEMBLY_SEND_CHATS_MESSAGE]({
        eventId: this.generalAssembly.eventId,
        assemblyId: this.generalAssembly.id,
        chatId: this.generalAssembly.chatId,
        message,
      });
    },
    async initialize() {
      await this[GET_ASSEMBLY_BY_ID](this.$route.params);
      this[CONNECT_TO_ASSEMBLY_SOCKET]({ assemblyId: this.$route.params.assemblyId });
    },
    saveAnswers(answerType) {
      if (!this.hasAnswered) {
        this[QUESTION_ANSWER]({
          ...this.$route.params,
          questionId: this.question.id,
          answerForm: { answer: answerType },
        });
      }
    },
    async approve() {
      this.saveAnswers(AnswerType.APPROVE);
    },
    async reject() {
      this.saveAnswers(AnswerType.REJECT);
    },
    async refrain() {
      this.saveAnswers(AnswerType.REFRAIN);
    },
    findResultCount(answerType) {
      const result = this.answers?.results?.find((result) => result.answer === answerType);

      return result?.count || 0;
    },
    approveCount() {
      return this.findResultCount(AnswerType.APPROVE);
    },
    rejectCount() {
      return this.findResultCount(AnswerType.REJECT);
    },
    refrainCount() {
      return this.findResultCount(AnswerType.REFRAIN);
    },
    answered(type) {
      return this.question.userAnswer?.answer === type;
    },
    goToProfile(userId) {
      this.$router.push({
        name: 'EventPublicProfile',
        params: { userId, eventId: this.generalAssembly.eventId },
      });
    },
    async loadMessages() {
      if (!this.assemblyMessagesAreLoading) {
        await this[ASSEMBLY_CHAT_LOAD_MESSAGES](this.$route.params);
      }
    },
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this[DISCONNECT_FROM_ASSEMBLY_SOCKET](this.$route.params.assemblyId);
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.general-assembly-container {
  min-height: calc(100vh - 6rem);
  background-size: cover;
  background-position: center;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.room {
  width: 100%;
}

.right-column {
  height: calc(100vh);
}

.back {
  padding: 1rem 0;

  &__button {
    font-size: $large-font-size;
  }
}

.chat {
  height: 100vh;
}

.question {
  &__wrapper {
    padding: 2rem 0;
  }

  &__times {
    padding: 0.5rem 0;
    font-size: $small-font-size;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.control {
  width: 80%;

  margin: 0.5rem;

  &__disabled {
    cursor: default;
  }
}

@include breakpoint(medium) {
  .general-assembly {
    &__chat {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 4rem);
      height: 100%;
    }
  }

  .room {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }

  .question {
    &__controls {
      flex-direction: row;
    }
  }

  .control {
    width: 25%;

    margin: 1rem;
  }

  .right-column {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 4rem);
  }

  .chat {
    flex: 3;
    height: auto;
  }
}
</style>
